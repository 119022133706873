export const gtmPageView = () => {
  const pageEvent = {
    event: "pageview",
  };
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(pageEvent);
  }
};

export const gtmEventTrack = (tagData) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    console.log(
      "Event track Object ==> ",
      JSON.stringify({
        event: tagData.tagName,
        action: tagData.action,
        category: tagData.category,
      })
    );
    window.dataLayer.push({
      event: tagData.tagName,
      action: tagData.action,
      category: tagData.category,
    });
  }
};
