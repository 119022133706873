import { Link } from "gatsby";
import React, { useState } from "react";
import logo from "../../images/logo.svg";
import logoElecboy from "../../images/logo_elecboy.svg";
import fwdLogo from "../../images/shopee/logo.svg";
import eirLogo from "../../images/eir/logo.svg"
import hcLogo from "../../images/homecredit/PH/hc-logo.svg";
import crossLogo from "../../images/homecredit/PH/cross_icon.svg";
import btLogo from "../../images/eir/bt_logo.svg"
import Dropdown from "react-dropdown";
import { changeLocale, IntlContextConsumer, useIntl } from "gatsby-plugin-intl";
import "react-dropdown/style.css";
import "./header.scss";
import { PARTNER_DETAILS } from "../../utils/constants";

function Header() {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);
  const onLangChange = (lang) => {
    changeLocale(lang.value);
  };

  const handleClick = () => {
    setModalOpen(true);
  };
  const logoFunct = () => {
    if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.SHOPEE) {
      return <img src={fwdLogo} alt="logo" className="logo" />;
    } else if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.ELECBOY) {
      return <img src={logoElecboy} alt="logo" className="logo" />;
    } else if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.HOMECREDIT) {
      return <img src={hcLogo} alt="logo" className="logo" />;
    } else { 
      return <img src={logo} alt="logo" className="logo" />;
    }
  };

  return (
    <header className="header-wrap">
      <div className={process.env.GATSBY_PARTNER !== 'eir' ? "main-container header-content" : "header-content"}>
        {process.env.GATSBY_PARTNER == PARTNER_DETAILS.EIR ?
          <img src={eirLogo} alt="logo" className="logo" /> :
          <Link to="/">
          {logoFunct()}
          {/* {process.env.GATSBY_PARTNER === 'shopee' ?
            <img src={fwdLogo} alt="logo" className="logo" /> :
            <img src={logo} alt="logo" className="logo" />} */}
        </Link>}
        {process.env.GATSBY_PARTNER == PARTNER_DETAILS.EIR && (
          <div
            style={{
              width: '122px',
              height: '39px',
              position: 'relative',
            }}
          >
            <img src={btLogo} alt="logo"
              style={{
                position: 'absolute',
                width: '80%',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }} />
          </div>
        )}
        <div className="lang-wrap">
          <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
              languages.length > 1 && (
                <Dropdown
                  options={languages}
                  onChange={onLangChange}
                  value={currentLocale}
                  placeholder="Lang"
                />
              )
            }
          </IntlContextConsumer>
        </div>
        {process.env.GATSBY_PARTNER == PARTNER_DETAILS.HOMECREDIT && (
            <div>
              <div className="tech-support" onClick={handleClick}>Tech Support</div>
              {modalOpen && (
                <div className="custom-modal overlay" id="techSupportModel">
                  <div className="inner-custom-modal tech-support-modal">
                    <div className="close-modal" onClick={() => setModalOpen(false)}>
                      <img src={crossLogo} alt="" />
                    </div>
                    <h3 className="modal-heading">{intl.formatMessage({id: "techSupportHeading"})}</h3>
                    <ol className="lower-alpha-list">
                      <li>{intl.formatMessage({id: "techSupportItem1"})}</li>
                      <li>{intl.formatMessage({id: "techSupportItem2"})}</li>
                      <li>{intl.formatMessage({id: "techSupportItem3"})}</li>
                      <li>{intl.formatMessage({id: "techSupportItem4"})}</li>
                    </ol>
                    <div className="modal-btn-wrap text-center">
                      <a className="btn btn-primary" href="https://admin.helpforsmartphone.com/bolttech/en/devices/" target="_blank" rel="noreferrer"
                        onClick={() => setModalOpen(false)}>{intl.formatMessage({id: "techSupportAccept"})}</a>
                      <button type="button" className="btn btn-secondary"
                        onClick={() => setModalOpen(false)}>{intl.formatMessage({id: "techSupportDecline"})}</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )} 
      </div>
    </header>
  );
}

export default Header;
